import React, { useCallback, useState } from 'react'

import { AppState } from '../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { editActions } from '../redux/actions/editAction'

import Header from './Header'
import Content from './Content'
import Dropzone from './Dropzone'
import { ColorSetting, ModalWithLinearProgress } from '../components'
import { Group } from '../classes'

import {
  ColorSetting as ColorSettingType,
  DialogProps,
  PhotoCount,
  WatermarkInfo,
} from '../utils/Types'
import { readImageFile } from '../utils/methods'
import Dialog, { openDialog } from '../components/Dialog'
import WatermarkSetting from './WatermarkSetting'

const Edit: React.FC = () => {
  const dispatch = useDispatch()
  const groups = useSelector((state: AppState) => state.edit.groups)
  const photoSize = useSelector((state: AppState) => state.edit.photoSize)

  const [isReading, setIsReadeing] = useState(false)
  const [readProggress, setReadProggress] = useState(0)

  const [photoCount, setPhotoCount] = useState<PhotoCount>({
    photo: 0,
    barcode: 0,
    uploaded: 0,
  })
  const [dialogProps, setDialogProps] = useState<DialogProps>({
    open: false,
    title: '',
    content: '',
    color: 'primary',
  })
  const [colorSetting, setColorSetting] = useState<ColorSettingType>({
    h: 50,
    s: 50,
    l: 50,
  })

  const [isWatermarkOpen, setIsWatermarkOpen] = useState(false)
  const [isColorOpen, setIsColorOpen] = useState(false)

  const closeDialog = React.useCallback((): void => {
    setDialogProps({
      open: false,
      title: '',
      content: '',
      color: 'primary',
    })
  }, [])

  const toggleWatermarkOpen = useCallback((): void => {
    setIsWatermarkOpen(!isWatermarkOpen)
  }, [isWatermarkOpen])

  const toggleColorOpen = useCallback((): void => {
    setIsColorOpen(!isColorOpen)
  }, [isColorOpen])

  const execComposition = async (
    newWatermarkInfo: WatermarkInfo
  ): Promise<void> => {
    dispatch(editActions.updateWatermarkInfo(newWatermarkInfo))

    toggleWatermarkOpen()
  }

  const deleteAllPhotos = React.useCallback((): void => {
    dispatch(editActions.updateGroups([]))
    setPhotoCount({
      photo: 0,
      barcode: 0,
      uploaded: 0,
    })
  }, [dispatch])

  const addPhotos = React.useCallback(
    async (photos: File[]) => {
      if (
        Number(process.env.REACT_APP_READ_MAX) <
        photos.length + photoCount.photo
      ) {
        openDialog({
          type: 'overPhotoCnt',
          setDialogProps,
          onClickOk: closeDialog,
        })
        return
      }

      setReadProggress(0)
      setIsReadeing(true)

      const newPhotoCount = Object.assign({}, photoCount)
      newPhotoCount.photo += photos.length
      setPhotoCount(newPhotoCount)

      const newGroup = new Group()

      for (let i = 0; i < photos.length; i++) {
        const readRes = await readImageFile(photos[i], photoSize)

        newGroup.addPhoto(readRes)

        setReadProggress(((i + 1) / photos.length) * 100)
      }

      setTimeout(() => {
        dispatch(editActions.updateGroups([...groups, newGroup]))

        setIsReadeing(false)
      }, 1000)
    },
    [dispatch, photoCount, photoSize, groups]
  )

  const execColorSetting = (colorSetting: ColorSettingType): void => {
    const newGroups = groups.map((group) => {
      const newGroup = group.clone()
      newGroup.photos.forEach((photo, _idx) => {
        photo.colorSetting = colorSetting
      })

      return newGroup
    })

    toggleColorOpen()

    setColorSetting(colorSetting)
    dispatch(editActions.updateGroups(newGroups))
  }

  return (
    <div>
      <Header
        photoCount={photoCount}
        onPhotoCountChange={setPhotoCount}
        onDeleteAllPhotos={deleteAllPhotos}
        onHsvClick={toggleColorOpen}
        onAddPhotos={addPhotos}
        onWatermarkClick={toggleWatermarkOpen}
      />
      <Content />
      <Dropzone onAddPhotos={addPhotos} />
      <ModalWithLinearProgress
        open={isReading}
        progress={readProggress}
        message="読込中..."
        color="primary"
      />
      <Dialog
        open={dialogProps.open}
        title={dialogProps.title}
        content={dialogProps.content}
        color={dialogProps.color}
        onClickCancel={dialogProps.onClickCancel}
        onClickOk={dialogProps.onClickOk}
      />
      <WatermarkSetting
        open={isWatermarkOpen}
        onClose={toggleWatermarkOpen}
        onExecComposition={execComposition}
      />
      <ColorSetting
        open={isColorOpen}
        src={groups.length === 0 ? '' : groups[0].photos[0].src}
        colorSetting={colorSetting}
        onApply={execColorSetting}
        onClose={toggleColorOpen}
      />
    </div>
  )
}

export default Edit
