import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import logo from '../assets/logo.png'
import { grey } from '@material-ui/core/colors'

const useStyles = makeStyles(() => ({
  root: {
    height: '50px',
    background: grey[800],
    padding: '15px 15px 0 0',
  },
  logo: {
    marginTop: -15,
    height: '65px',
  },
  logout: {
    float: 'right',
  },
}))

const Header: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <img src={logo} className={classes.logo} alt="logo" />
    </div>
  )
}

export default Header
