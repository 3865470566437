import actionCreatorFactory from 'typescript-fsa'
import {
  FtpSetting,
  PhotoSize,
  Project,
  WatermarkInfo,
} from '../../utils/Types'
import { default as Group } from '../../classes/Group'

const actionCreator = actionCreatorFactory()

export const editActions = {
  setProject: actionCreator<{
    project: Project & { width: number; height: number }
    watermark: WatermarkInfo
  }>('SET_PROJECT'),
  updatePhotoSize: actionCreator<PhotoSize>('UPDATE_PHOTO_SIZE'),
  updateProjectName: actionCreator<string>('UPDATE_PROJECT_NAME'),
  updateGroups: actionCreator<Group[]>('UPDATE_GROUPS'),
  updateGroup: actionCreator<Group>('UPDATE_GROUP'),
  updateWatermarkInfo: actionCreator<WatermarkInfo>('UPDATE_WATERMARK_INFO'),
  clearWatermarkInfo: actionCreator('CLEAR_WATERMARK_INFO'),
  resetStore: actionCreator('RESET_STORE'),
  updateLatestWatermark: actionCreator<string>('UPDATE_LATEST_WATERMARK'),
  updateIsRevError: actionCreator<boolean>('UPDATE_IS_REV_ERROR'),
  updateFtpSetting: actionCreator<FtpSetting>('UPDATE_FTP_SETTING'),
}
