import React, { useEffect, useRef, useState } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'

import { useSelector } from 'react-redux'
import { AppState } from '../redux/store'

import {
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  Modal,
  TextField,
} from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  Clear as ClearIcon,
  DeleteForever as DeleteForeverIcon,
  FileCopy as FileCopyIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@material-ui/icons'

import Photo from './Photo'
import { ColorSetting, WatermarkInfo } from '../utils/Types'
import { Group as GroupClass, Photo as PhotoClass } from '../classes'
import { asyncImageReader, paddingLeft } from '../utils/methods'
import { hsvAdjustment } from '../utils/imageProcessing'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    header: {
      height: '58px',
      background: blueGrey[800],
      borderRadius: '10px',
      marginBottom: '10px',
    },
    releaseGroup: {
      display: 'inline-table',
      verticalAlign: 'top',
      marginLeft: '5px',
    },
    releaseGroupPlaceholder: {
      display: 'inline-block',
      width: '63px',
    },
    groupName: {
      margin: '10px 15px 0 10px',
    },
    groupNameError: {
      '& div': {
        '& fieldset': {
          borderWidth: '3px',
        },
      },
    },
    checkbox: {
      marginTop: '9px',
      color: 'white',
    },
    collapse: {
      display: 'inline-table',
      verticalAlign: 'top',
      float: 'right',
    },
    enlargeContainer: {
      width: '100%',
      textAlign: 'center',
      '&:focus': {
        outline: 'none',
      },
    },
    enlargeBody: {
      position: 'relative',
      display: 'table-cell',
      '&:focus': {
        outline: 'none',
      },
    },
    enlargeImg: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    enlargeWatermark: {
      position: 'absolute',
    },
  })
)

const ReleaseGroupButton = ({
  isTop,
  className,
  onClick,
}: {
  isTop: boolean
  className: string
  onClick: () => void
}) => {
  if (isTop) {
    return <div className={className} />
  }

  return (
    <IconButton
      aria-label="delete"
      className={className}
      onClick={onClick}
      title="グループ解除"
    >
      <ClearIcon fontSize="large" color="secondary" />
    </IconButton>
  )
}

type GroupBarProps = {
  isTop: boolean
  groupNameDefault: string
  isHeadBarcode: boolean
  isError: boolean
  collapse: boolean
  onReleaseGroup: () => void
  onGroupNameBlur: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  onChangeHeadBarcode: () => void
  onChangeCollapse: () => void
  onDeleteGroup: () => void
  onDuplicateGroup: () => void
}
const GroupBar: React.FC<GroupBarProps> = (props: GroupBarProps) => {
  const classes = useStyles()

  const [groupName, setGroupName] = useState(props.groupNameDefault)

  const onGroupNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGroupName(event.target.value.replace(/[¥¥/:?*"<>|]/g, ''))
  }

  return (
    <div className={classes.header}>
      <ReleaseGroupButton
        isTop={props.isTop}
        className={
          props.isTop ? classes.releaseGroupPlaceholder : classes.releaseGroup
        }
        onClick={props.onReleaseGroup}
      />
      <TextField
        error={props.isError}
        variant="outlined"
        size="small"
        label="グループ名"
        className={
          classes.groupName +
          (props.isError ? ' ' + classes.groupNameError : '')
        }
        value={groupName}
        onChange={onGroupNameChange}
        onBlur={props.onGroupNameBlur}
      />
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={props.isHeadBarcode}
            onChange={props.onChangeHeadBarcode}
          />
        }
        label="先頭バーコード"
        className={classes.checkbox}
      />
      <IconButton className={classes.collapse} onClick={props.onChangeCollapse}>
        {props.collapse ? (
          <KeyboardArrowUpIcon fontSize="large" />
        ) : (
          <KeyboardArrowDownIcon fontSize="large" />
        )}
      </IconButton>
      <IconButton
        aria-label="delete"
        title="グループ削除"
        className={classes.collapse}
        onClick={props.onDeleteGroup}
      >
        <DeleteForeverIcon fontSize="large" color="secondary" />
      </IconButton>
      <IconButton
        aria-label="duplicate"
        title="グループ複製"
        className={classes.collapse}
        onClick={props.onDuplicateGroup}
      >
        <FileCopyIcon fontSize="large" color="primary" />
      </IconButton>
    </div>
  )
}

type SortablePhotoProps = {
  photo: PhotoClass
  onEnlargePhoto: (photo: PhotoClass) => void
  onSplitGroup: (photoId: string) => void
  onDuplicatePhoto: (photoId: string) => void
  onDeletePhoto: (photoId: string) => void
  onColorChange: (id: string, colorSetting: ColorSetting) => void
}
const SortablePhoto = SortableElement((props: SortablePhotoProps) => {
  return (
    <Photo
      key={props.photo.id}
      photo={props.photo}
      onEnlargePhoto={props.onEnlargePhoto}
      onSplitGroup={props.onSplitGroup}
      onDuplicatePhoto={props.onDuplicatePhoto}
      onDeletePhoto={props.onDeletePhoto}
      onColorChange={props.onColorChange}
    />
  )
})

type SortablePhotosProps = {
  photos: PhotoClass[]
  onEnlargePhoto: (photo: PhotoClass) => void
  onSplitGroup: (photoId: string) => void
  onDuplicatePhoto: (photoId: string) => void
  onDeletePhoto: (photoId: string) => void
  onColorChange: (id: string, colorSetting: ColorSetting) => void
}
const SortablePhotos = SortableContainer((props: SortablePhotosProps) => {
  return (
    <div>
      {props.photos.map((photo, index) => {
        return (
          <SortablePhoto
            key={photo.id}
            index={index}
            photo={photo}
            onEnlargePhoto={props.onEnlargePhoto}
            onSplitGroup={props.onSplitGroup}
            onDuplicatePhoto={props.onDuplicatePhoto}
            onDeletePhoto={props.onDeletePhoto}
            onColorChange={props.onColorChange}
          />
        )
      })}
    </div>
  )
})

const enlargeSize = 500
const getEnlargeStyle = (size: { width: number; height: number }) => {
  let width = size.width
  let height = size.height

  if (width <= height && enlargeSize < height) {
    width *= enlargeSize / height
    height = enlargeSize
  } else if (height < width && enlargeSize < width) {
    height *= enlargeSize / width
    width = enlargeSize
  }

  return {
    width,
    height,
  }
}

const getWatermarkStyle = (
  watermarkInfo: WatermarkInfo,
  previewSize: number
) => {
  let ratio = 0
  if (watermarkInfo.previewSize.width < watermarkInfo.previewSize.height) {
    ratio = previewSize / watermarkInfo.previewSize.height
  } else {
    ratio = previewSize / watermarkInfo.previewSize.width
  }

  return {
    opacity: watermarkInfo.opacity / 100,
    width: watermarkInfo.size.width * ratio,
    height: watermarkInfo.size.height * ratio,
    top: watermarkInfo.position.y * ratio,
    left: watermarkInfo.position.x * ratio,
  }
}

const EnlargeModal = ({
  isEnlarge,
  photo,
  watermarkInfo,
  onNext,
  onPrevious,
  onClose,
}: {
  isEnlarge: boolean
  photo: PhotoClass | undefined
  watermarkInfo: WatermarkInfo
  onNext: () => void
  onPrevious: () => void
  onClose: () => void
}) => {
  const classes = useStyles()
  const photoSize = useSelector((state: AppState) => state.edit.photoSize)
  const enlargeStyle = getEnlargeStyle(photoSize)
  const watermarkStyle = getWatermarkStyle(watermarkInfo, 500)

  const [isSetCanvas, setIsCanvas] = useState(false)

  const src = photo?.src
  const colorSetting = photo?.colorSetting

  const canvasElement = useRef<HTMLCanvasElement>(
    document.createElement('canvas')
  )
  const setCanvasElement = React.useCallback((node: HTMLCanvasElement) => {
    canvasElement.current = node
    // console.log(node)

    if (node) {
      setIsCanvas(true)
    } else {
      setIsCanvas(false)
    }
  }, [])

  useEffect(() => {
    if (
      !(
        src &&
        colorSetting &&
        isEnlarge &&
        canvasElement.current &&
        isSetCanvas
      )
    ) {
      return
    }

    ;(async () => {
      const ctx = canvasElement.current.getContext('2d')
      if (ctx === null) {
        return
      }

      const image = await asyncImageReader(src)
      let width = image.width
      let height = image.height
      if (image.width < image.height) {
        width *= image.width / image.height
      } else {
        height *= image.height / image.width
      }

      canvasElement.current.width = width
      canvasElement.current.height = height

      ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, width, height)

      if (
        !(
          colorSetting.h === 50 &&
          colorSetting.s === 50 &&
          colorSetting.l === 50
        )
      ) {
        let newImageData = ctx.getImageData(0, 0, width, height)

        newImageData = hsvAdjustment(newImageData, {
          h: colorSetting.h,
          s: colorSetting.s,
          l: colorSetting.l,
        })

        ctx.putImageData(newImageData, 0, 0)
      }
    })()
  }, [src, colorSetting, isEnlarge, canvasElement.current, setIsCanvas])

  const stopClose = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation()
  }

  return (
    <Modal
      open={isEnlarge}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={onClose}
    >
      <div className={classes.enlargeContainer} onClick={onClose}>
        <div
          style={{
            display: 'table',
            margin: '50px auto 0 auto',
          }}
          onClick={stopClose}
        >
          <div
            style={{
              display: 'table-cell',
              verticalAlign: 'middle',
            }}
          >
            <IconButton color="default" title="previous" onClick={onPrevious}>
              <ArrowBackIosIcon fontSize="large" />
            </IconButton>
          </div>
          <div style={enlargeStyle} className={classes.enlargeBody}>
            <canvas ref={setCanvasElement} className={classes.enlargeImg} />
            {photo?.name === '001' ? (
              <img
                style={watermarkStyle}
                className={classes.enlargeWatermark}
                src={watermarkInfo.src}
                alt=""
              />
            ) : (
              <></>
            )}
            <div
              style={{
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                position: 'absolute',
              }}
            />
          </div>
          <div
            style={{
              display: 'table-cell',
              verticalAlign: 'middle',
            }}
          >
            <IconButton color="default" title="next" onClick={onNext}>
              <ArrowForwardIosIcon fontSize="large" />
            </IconButton>
          </div>
        </div>
      </div>
    </Modal>
  )
}

type Props = {
  isTop: boolean
  group: GroupClass
  onReleaseGroup: (groupId: string) => void
  onDuplicateGroup: (groupId: string, groupName: string) => void
  onDeleteGroup: (groupId: string, groupName: string) => void
  onSplitGroup: (groupId: string, photoId: string) => void
  onUpdateGroup: (newGroup: GroupClass) => void
}

const Group: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const [collapse, setCollapse] = useState(true)
  const [isEnlarge, setIsEnlarge] = useState(false)
  const [enlargePhoto, setEnlargePhoto] = useState<PhotoClass | undefined>(
    undefined
  )

  const watermarkInfo = useSelector(
    (state: AppState) => state.edit.watermarkInfo
  )

  const group = props.group
  const releaseGroup = props.onReleaseGroup
  const duplicateGroup = props.onDuplicateGroup
  const deleteGroup = props.onDeleteGroup
  const splitGroup = props.onSplitGroup
  const updateGroup = props.onUpdateGroup

  const toggleHeadBarcode = (): void => {
    const newGroup = group.clone()
    newGroup.toggleIsHeadBarcode()

    props.onUpdateGroup(newGroup)
  }

  const toggleCollapse = (): void => {
    setCollapse(!collapse)
  }

  const toggleEnlarge = (): void => {
    setIsEnlarge(!isEnlarge)
  }

  const onGroupNameBlurHandler = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (group.name !== event.target.value) {
      const newGroup = group.clone()
      newGroup.name = event.target.value
      newGroup.isError = false

      props.onUpdateGroup(newGroup)
    }
  }

  const releaseGroupHandler = React.useCallback((): void => {
    releaseGroup(group.id)
  }, [group.id, releaseGroup])

  const duplicateGroupHandler = React.useCallback((): void => {
    duplicateGroup(group.id, group.name)
  }, [group.id, group.name, duplicateGroup])

  const deleteGroupHandler = React.useCallback((): void => {
    deleteGroup(group.id, group.name)
  }, [group.id, group.name, deleteGroup])

  const splitGroupHandler = React.useCallback(
    (photoId: string) => {
      splitGroup(group.id, photoId)
    },
    [group.id, splitGroup]
  )

  const duplicatePhoto = React.useCallback(
    (photoId: string) => {
      const newGroup = group.clone()

      newGroup.duplicatePhoto(photoId)

      updateGroup(newGroup)
    },
    [group, updateGroup]
  )

  const deletePhoto = React.useCallback(
    (photoId: string) => {
      const newGroup = group.clone()
      newGroup.deletePhoto(photoId)

      updateGroup(newGroup)
    },
    [group, updateGroup]
  )

  const onEnlargePhoto = (photo: PhotoClass) => {
    setEnlargePhoto(photo)
    toggleEnlarge()
  }

  const onEnlargeNext = () => {
    const nowIndex = group.photos.findIndex(
      (photo) => photo.id === enlargePhoto?.id
    )

    if (nowIndex < 0) {
      return
    }

    let nextIndex = nowIndex + 1
    if (group.photos.length <= nextIndex) {
      nextIndex = 0
    }

    setEnlargePhoto(group.photos[nextIndex])
  }

  const onEnlargePrevious = () => {
    const nowIndex = group.photos.findIndex(
      (photo) => photo.id === enlargePhoto?.id
    )

    if (nowIndex < 0) {
      return
    }

    let nextIndex = nowIndex - 1
    if (nextIndex < 0) {
      nextIndex = group.photos.length - 1
    }

    setEnlargePhoto(group.photos[nextIndex])
  }

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number
    newIndex: number
  }) => {
    if (oldIndex === newIndex) {
      return
    }

    const newGroup = group.clone()
    newGroup.photos = arrayMove(newGroup.photos, oldIndex, newIndex)

    const offset = newGroup.isHeadBarcode ? 0 : 1
    newGroup.photos.forEach((photo, index) => {
      photo.name = paddingLeft(index + offset, '0', 3)
    })

    updateGroup(newGroup)
  }

  const onColorChange = React.useCallback(
    (id: string, colorSetting: ColorSetting): void => {
      const newGroup = group.clone()
      const photoIdx = newGroup.photos.findIndex((photo) => photo.id === id)

      const newPhoto = newGroup.photos[photoIdx].clone()
      newPhoto.colorSetting = colorSetting
      newGroup.photos[photoIdx] = newPhoto

      updateGroup(newGroup)
    },
    [group, updateGroup]
  )

  return (
    <div className={classes.root}>
      <GroupBar
        isTop={props.isTop}
        groupNameDefault={group.name}
        isHeadBarcode={group.isHeadBarcode}
        isError={group.isError}
        collapse={collapse}
        onReleaseGroup={releaseGroupHandler}
        onGroupNameBlur={onGroupNameBlurHandler}
        onChangeHeadBarcode={toggleHeadBarcode}
        onChangeCollapse={toggleCollapse}
        onDeleteGroup={deleteGroupHandler}
        onDuplicateGroup={duplicateGroupHandler}
      />
      <Collapse in={collapse}>
        <SortablePhotos
          axis="xy"
          distance={10}
          onSortEnd={onSortEnd}
          photos={group.photos}
          onEnlargePhoto={onEnlargePhoto}
          onSplitGroup={splitGroupHandler}
          onDuplicatePhoto={duplicatePhoto}
          onDeletePhoto={deletePhoto}
          onColorChange={onColorChange}
        />
      </Collapse>
      <EnlargeModal
        isEnlarge={isEnlarge}
        photo={enlargePhoto}
        watermarkInfo={watermarkInfo}
        onNext={onEnlargeNext}
        onPrevious={onEnlargePrevious}
        onClose={toggleEnlarge}
      />
    </div>
  )
}

const areEqual = (prevState: Props, nextState: Props): boolean => {
  return (
    prevState.group === nextState.group && prevState.isTop === nextState.isTop
  )
}

export default React.memo(Group, areEqual)
