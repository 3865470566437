import React, { useEffect, useState } from 'react'

import {
  Button,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Modal,
  Paper,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
} from '@material-ui/icons'

import { FtpSetting } from '../utils/Types'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: '50px auto 0 auto',
      width: 500,
      padding: 10,
      '&:focus': {
        outline: 'none',
      },
    },
    title: {
      marginBottom: 15,
      textAlign: 'center',
    },
    topGrid: {
      marginBottom: 0,
    },
    bottomGrid: {
      marginTop: 10,
    },
  })
)

const uploadState = {
  none: 0,
  uploading: 1,
  ftpConnError: 2,
  completed: 9,
}

type Props = {
  open: boolean
  ftpSetting: FtpSetting
  onClose: () => void
  onUpload: (
    host: string,
    port: string,
    user: string,
    password: string,
    dir: string
  ) => void
}

const FtpUpload: React.FC<Props> = (props: Props) => {
  const classes = useStyles()

  const [host, setHost] = useState('')
  const [port, setPort] = useState('21')
  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')
  const [dir, setDir] = useState('/')
  const [state, setState] = useState(uploadState.none)
  const [message, setMessage] = useState('')

  useEffect(() => {
    setHost(props.ftpSetting.host)
    setPort(props.ftpSetting.port)
    setUser(props.ftpSetting.user)
    setPassword(props.ftpSetting.password)
    setDir(props.ftpSetting.dir)
  }, [props.ftpSetting])

  const onTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const target = event.currentTarget.name
    const value = event.currentTarget.value

    switch (target) {
      case 'host':
        setHost(value)
        break
      case 'port': {
        const pattern = /^\d*$/
        if (pattern.test(value)) {
          setPort(value)
        }
        break
      }
      case 'user':
        setUser(value)
        break
      case 'password':
        setPassword(value)
        break
      case 'dir':
        setDir(value)
        break
    }

    setState(uploadState.none)
  }

  const onCancel = () => {
    setState(uploadState.none)
    props.onClose()
  }

  const onUpload = () => {
    props.onUpload(host, port, user, password, dir)
  }

  const getMessage = (): React.ReactElement => {
    let icon = <></>

    switch (state) {
      case uploadState.uploading:
        icon = (
          <CircularProgress size={23} style={{ verticalAlign: 'bottom' }} />
        )
        break
      case uploadState.ftpConnError:
        icon = (
          <ErrorIcon color="secondary" style={{ verticalAlign: 'bottom' }} />
        )
        break
      case uploadState.completed:
        icon = (
          <CheckCircleIcon
            style={{ verticalAlign: 'bottom', color: '#80FF75' }}
          />
        )
        break
      default:
        return <></>
    }

    return (
      <div style={{ textAlign: 'center' }}>
        {icon}
        <span
          style={{
            marginLeft: 5,
            fontSize: 13,
            verticalAlign: 'bottom',
          }}
        >
          {message}
        </span>
      </div>
    )
  }

  return (
    <Modal
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Paper className={classes.root}>
        <Typography variant="h6" className={classes.title}>
          接続情報
        </Typography>
        <Grid container spacing={2} className={classes.topGrid}>
          <Grid item xs={8}>
            <TextField
              value={host}
              label="ホスト名"
              variant="outlined"
              size="small"
              fullWidth
              onChange={onTextChange}
              inputProps={{ name: 'host' }}
              disabled={state === uploadState.uploading}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={port}
              label="ポート番号"
              variant="outlined"
              size="small"
              fullWidth
              onChange={onTextChange}
              inputProps={{ name: 'port' }}
              disabled={state === uploadState.uploading}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={user}
              label="ユーザ名"
              variant="outlined"
              size="small"
              fullWidth
              onChange={onTextChange}
              inputProps={{ name: 'user' }}
              disabled={state === uploadState.uploading}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={password}
              label="パスワード"
              type="password"
              variant="outlined"
              size="small"
              fullWidth
              onChange={onTextChange}
              inputProps={{ name: 'password' }}
              disabled={state === uploadState.uploading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={dir}
              label="ディレクトリ"
              variant="outlined"
              size="small"
              fullWidth
              onChange={onTextChange}
              inputProps={{ name: 'dir' }}
              disabled={state === uploadState.uploading}
            />
          </Grid>
        </Grid>

        <Divider />

        <Grid
          container
          justify="space-between"
          alignItems="center"
          className={classes.bottomGrid}
        >
          <Grid item sm={3}>
            <Button
              color="default"
              variant="contained"
              fullWidth
              onClick={onCancel}
              disabled={state === uploadState.uploading}
            >
              {state === uploadState.completed ? '終了' : 'キャンセル'}
            </Button>
          </Grid>
          <Grid item sm={5}>
            {getMessage()}
          </Grid>
          <Grid item sm={4}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={onUpload}
              disabled={
                0 <= [host, port, user, password, dir].indexOf('') ||
                state === uploadState.uploading
              }
            >
              アップロード
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  )
}

export default FtpUpload
