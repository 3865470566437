import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { editActions } from '../actions/editAction'
import { initialState } from '../states/editState'
import { default as Group } from '../../classes/Group'

const EditReducer = reducerWithInitialState(initialState)
  .case(editActions.setProject, (state, data) => {
    let object = Object.assign({}, state, {
      projectName: data.project.name,
      groups: Group.createGroups(data.project.groups),
      photoSize: {
        width: data.project.width,
        height: data.project.height,
      },
    })

    if (data.watermark !== null) {
      object = Object.assign(object, { watermarkInfo: data.watermark })
    }

    return object
  })
  .case(editActions.updatePhotoSize, (state, photoSize) => {
    return Object.assign({}, state, { photoSize })
  })
  .case(editActions.updateProjectName, (state, projectName) => {
    return Object.assign({}, state, { projectName })
  })
  .case(editActions.updateGroups, (state, groups) => {
    return Object.assign({}, state, { groups })
  })
  .case(editActions.updateGroup, (state, updateGroup) => {
    const groups = state.groups
    const updateGroupIndex = groups.findIndex(
      (group) => group.id === updateGroup.id
    )

    const first = groups.slice(0, updateGroupIndex)
    const last = groups.slice(updateGroupIndex + 1)

    if (updateGroup.photos.length !== 0) {
      return Object.assign({}, state, {
        groups: [...first, updateGroup, ...last],
      })
    } else {
      return Object.assign({}, state, { groups: [...first, ...last] })
    }
  })
  .case(editActions.updateWatermarkInfo, (state, watermarkInfo) => {
    return Object.assign({}, state, { watermarkInfo })
  })
  .case(editActions.clearWatermarkInfo, (state) => {
    return Object.assign({}, state, {
      watermarkInfo: {
        src: '',
        size: { width: 0, height: 0 },
        position: { x: 0, y: 0 },
        opacity: 50,
        previewSize: { width: 1000, height: 1000 },
      },
    })
  })
  .case(editActions.resetStore, (state) => {
    return Object.assign({}, initialState, {
      latestWatermark: state.latestWatermark,
    })
  })
  .case(editActions.updateLatestWatermark, (state, latestWatermark) => {
    return Object.assign({}, state, { latestWatermark })
  })
  .case(editActions.updateIsRevError, (state, isRevError) => {
    return Object.assign({}, state, { isRevError })
  })
  .case(editActions.updateFtpSetting, (state, ftpSetting) => {
    return Object.assign({}, state, { ftpSetting })
  })

export default EditReducer
