import { PhotoSize, WatermarkInfo } from './Types'
import { Group } from '../classes/'

export const deepCopy = <T>(object: T): T => {
  const result: T = JSON.parse(JSON.stringify(object))

  return result
}

export const findGroupById = (
  groups: Group[],
  id: string,
  isCopy = false
): [Group, number] => {
  const index = groups.findIndex((group) => group.id === id)
  let group = groups[index]

  if (isCopy) {
    group = deepCopy(group)
  }

  return [group, index]
}

export const paddingLeft = (
  target: number | string,
  paddingStr: string,
  len: number
): string => {
  let result = '' + target

  if (paddingStr.length === 0) {
    return result
  }

  while (result.length < len) {
    result = paddingStr + result
  }

  return result
}

export const asyncFileReader = (file: File | undefined): Promise<string> => {
  return new Promise((resolve, _reject) => {
    if (file === undefined) {
      resolve('')
      return
    }

    const reader = new FileReader()

    reader.onload = (e) => {
      if (e.target !== null && typeof e.target.result === 'string') {
        resolve(e.target.result)
      }
    }

    reader.readAsDataURL(file)
  })
}

export const asyncImageReader = (src: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, _reject) => {
    const image = new Image()
    image.crossOrigin = 'Anonymous'
    image.onload = (): void => {
      resolve(image)
    }

    if (src === '') {
      resolve(image)
    } else {
      image.src = src
    }
  })
}

export const resize = (
  src: string,
  size: { width: number; height: number }
): Promise<string> => {
  return new Promise((resolve, _reject) => {
    ;(async () => {
      const image = await asyncImageReader(src)

      const canvas = document.createElement('canvas')
      canvas.width = size.width
      canvas.height = size.height

      let sx = 0
      let sy = 0
      let sw = image.width
      let sh = image.height

      let ratio = 1
      if (size.height < size.width) {
        ratio = size.height / size.width
        sh = sw * ratio
        sy = (image.height - sh) / 2
      } else {
        ratio = size.width / size.height
        sw = sh * ratio
        sx = (image.width - sw) / 2
      }

      const ctx = canvas.getContext('2d')
      if (ctx !== null) {
        ctx.drawImage(image, sx, sy, sw, sh, 0, 0, size.width, size.height)
        resolve(canvas.toDataURL('image/jpeg'))
      } else {
        resolve('')
      }
    })()
  })
}

export const readImageFile = async (
  file: File,
  size?: { width: number; height: number }
): Promise<string> => {
  if (size === undefined) {
    return window.URL.createObjectURL(file)
  }

  return await resize(window.URL.createObjectURL(file), size)
}

export const alphaBlend = async (
  base: string,
  blend: string,
  position: { x: number; y: number },
  alpha: number,
  size: { width: number; height: number }
): Promise<string> => {
  const baseImage = await asyncImageReader(base)
  const blendImage = await asyncImageReader(blend)

  const blendCanvas = document.createElement('canvas')
  blendCanvas.width = blendImage.width
  blendCanvas.height = blendImage.height

  const blendCtx = blendCanvas.getContext('2d')
  if (blendCtx !== null) {
    blendCtx.drawImage(blendImage, 0, 0, blendImage.width, blendImage.height)
    const tmpData = blendCtx.getImageData(
      0,
      0,
      blendImage.width,
      blendImage.height
    )
    const data = tmpData.data

    for (let i = 0; i < data.length; i += 4) {
      data[i + 3] *= alpha / 100
    }

    blendCtx.putImageData(tmpData, 0, 0)
  }

  const baseCanvas = document.createElement('canvas')
  baseCanvas.width = baseImage.width
  baseCanvas.height = baseImage.height

  const ctx = baseCanvas.getContext('2d')
  if (ctx !== null) {
    ctx.drawImage(baseImage, 0, 0)
    ctx.drawImage(blendCanvas, position.x, position.y, size.width, size.height)
  }

  return baseCanvas.toDataURL('image/jpeg')
}

export const createUploadData = async (
  projectId: string,
  projectName: string,
  photoSize: PhotoSize,
  groups: Group[],
  watermarkInfo: WatermarkInfo
): Promise<FormData> => {
  const fd = new FormData()

  fd.append(
    'project',
    JSON.stringify({
      id: projectId,
      name: projectName,
      photoSize: photoSize,
    })
  )

  const groupDatas: unknown[] = []
  for (const group of groups) {
    groupDatas.push({
      name: group.name,
      photoCnt: group.photos.length,
      isHeadBarcode: group.isHeadBarcode,
    })

    const offset = group.isHeadBarcode ? 0 : 1
    await Promise.all(
      group.photos.map(async (photo, index) => {
        let src = photo.src
        if (src.substr(0, 4) === 'http') {
          const image = await asyncImageReader(src)
          const canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height
          const ctx = canvas.getContext('2d')

          if (ctx) {
            ctx.drawImage(image, 0, 0)
            src = canvas.toDataURL('image/jpeg')
          }
        }
        const bin = atob(src.replace(/^.*,/, ''))
        const tmp = photo.src.match(/(:)([a-z/]+)(;)/)
        const mimeType = tmp !== null ? tmp[2] : ''

        const content = new Uint8Array(bin.length)
        for (let i = 0, l = bin.length; l > i; i++) {
          content[i] = bin.charCodeAt(i)
        }

        const blob = new Blob([content], { type: mimeType })

        fd.append(
          'photos[]',
          blob,
          group.name + '_' + paddingLeft(index + offset, '0', 3)
        )
      })
    )
  }
  fd.append('groups', JSON.stringify({ data: groupDatas }))

  let watermarkOpacity = ''
  if (watermarkInfo.src !== '') {
    const watermarkImage = await asyncImageReader(watermarkInfo.src)

    const watermarkCanvas = document.createElement('canvas')
    watermarkCanvas.width = watermarkImage.width
    watermarkCanvas.height = watermarkImage.height

    const watermarkCtx = watermarkCanvas.getContext('2d')
    if (watermarkCtx !== null) {
      watermarkCtx.drawImage(
        watermarkImage,
        0,
        0,
        watermarkImage.width,
        watermarkImage.height
      )
      const tmpData = watermarkCtx.getImageData(
        0,
        0,
        watermarkImage.width,
        watermarkImage.height
      )
      const data = tmpData.data

      for (let i = 0; i < data.length; i += 4) {
        data[i + 3] *= watermarkInfo.opacity / 100
      }

      watermarkCtx.putImageData(tmpData, 0, 0)
    }

    watermarkOpacity = watermarkCanvas.toDataURL()
  }

  fd.append(
    'watermark',
    JSON.stringify({
      data: Object.assign({ src_opacity: watermarkOpacity }, watermarkInfo),
    })
  )

  return fd
}
