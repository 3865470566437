import { FtpSetting, PhotoSize, WatermarkInfo } from '../../utils/Types'
import { default as Group } from '../../classes/Group'

interface EditState {
  photoSize: PhotoSize
  projectName: string
  groups: Group[]
  watermarkInfo: WatermarkInfo
  latestWatermark: string
  isRevError: boolean
  ftpSetting: FtpSetting
}

export const initialState: EditState = {
  photoSize: { width: 1000, height: 1000 },
  projectName: '',
  groups: [],
  watermarkInfo: {
    src: '',
    size: { width: 0, height: 0 },
    position: { x: 0, y: 0 },
    opacity: 50,
    previewSize: { width: 1000, height: 1000 },
  },
  latestWatermark: '',
  isRevError: false,
  ftpSetting: {
    host: '',
    port: '21',
    user: '',
    password: '',
    dir: '',
  },
}

export default EditState
