import { nanoid } from 'nanoid'

import { ColorSetting, Photo as PhotoType } from '../utils/Types'

export default class Photo implements PhotoType {
  id: string
  name: string
  src: string
  colorSetting: ColorSetting

  constructor(args: {
    name: string
    src: string
    id?: string
    colorSetting?: ColorSetting
  }) {
    if (args.id) {
      this.id = args.id
    } else {
      this.id = nanoid(10)
    }
    this.name = args.name
    this.src = args.src

    if (args.colorSetting) {
      this.colorSetting = args.colorSetting
    } else {
      this.colorSetting = {
        h: 50,
        s: 50,
        l: 50,
      }
    }
  }

  clone = (): Photo => {
    return new Photo(this)
  }
}
