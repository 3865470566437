import React, { useEffect, useState } from 'react'

import { RndDragCallback, RndResizeCallback } from 'react-rnd'

import { AppState } from '../redux/store'
import { useSelector } from 'react-redux'

import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Modal,
  Paper,
  Slider,
  OutlinedInput,
  InputAdornment,
  Divider,
} from '@material-ui/core'

import Watermark from './Watermark'
import { PREVIEW_SIZE } from '../utils/constant'
import { WatermarkInfo } from '../utils/Types'
import { asyncFileReader, asyncImageReader } from '../utils/methods'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: '50px auto 0 auto',
      width: PREVIEW_SIZE,
      padding: 10,
      '&:focus': {
        outline: 'none',
      },
    },
    imageArea: {
      margin: '0 auto 10px auto',
    },
    opacityText: {
      padding: '7px 0 7px 7px',
    },
    previewImage: {
      display: 'inline-block',
      maxWidth: PREVIEW_SIZE,
      maxHeight: PREVIEW_SIZE,
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    previewImageCover: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    bottomGrid: {
      marginTop: 10,
    },
    inputFileHide: {
      opacity: '0',
      appearance: 'none',
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
  })
)

type Props = {
  open: boolean
  onClose: () => void
  onExecComposition: (watermark: WatermarkInfo) => void
}

const WatermarkSetting: React.FC<Props> = (props: Props) => {
  const classes = useStyles()

  const photoSize = useSelector((state: AppState) => state.edit.photoSize)
  const watermarkInfo = useSelector(
    (state: AppState) => state.edit.watermarkInfo
  )
  const [watermark, setWatermark] = useState('')
  const [size, setSize] = useState({ width: 0, height: 0 })
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [opacity, setOpacity] = useState(50)

  const groups = useSelector((state: AppState) => state.edit.groups)
  const [previewSrc, setPreviewSrc] = useState('')
  const [previewOriginSize, setPreviewOriginSize] = useState({
    width: 0,
    height: 0,
  })
  const [previewSize, setPreviewSize] = useState({
    width: 0,
    height: 0,
  })
  const [previewRatio, setPreviewRatio] = useState(0)

  useEffect(() => {
    ;(async () => {
      let src = ''
      if (groups.length === 0) {
        setPreviewSrc('')
        return
      }

      const photo = groups[0].photos.find((photo) => photo.name !== '000')
      if (photo) {
        src = photo.src

        let width = photoSize.width
        let height = photoSize.height

        setPreviewOriginSize({ width, height })

        let newPreviewRatio = 0
        if (photoSize.width < photoSize.height) {
          newPreviewRatio = PREVIEW_SIZE / photoSize.height
          width = width * newPreviewRatio
          height = PREVIEW_SIZE
        } else {
          newPreviewRatio = PREVIEW_SIZE / photoSize.width
          width = PREVIEW_SIZE
          height = height * newPreviewRatio
        }

        setPreviewSize({ width, height })
        setPreviewRatio(newPreviewRatio)
      }

      setPreviewSrc(src)
    })()
  }, [groups, photoSize])

  useEffect(() => {
    setWatermark(watermarkInfo.src)
    setSize({
      width: watermarkInfo.size.width * previewRatio,
      height: watermarkInfo.size.height * previewRatio,
    })
    setPosition({
      x: watermarkInfo.position.x * previewRatio,
      y: watermarkInfo.position.y * previewRatio,
    })
    setOpacity(watermarkInfo.opacity)
  }, [watermarkInfo, previewRatio])

  const onOpacityTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setOpacity(Number(event.target.value))
  }

  const onOpacitySliderChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: number | number[]
  ): void => {
    if (Array.isArray(value)) {
      setOpacity(value[0])
    } else {
      setOpacity(value)
    }
  }
  const onCancelHandler = (): void => {
    props.onClose()

    setWatermark(watermarkInfo.src)
    setSize({
      width: watermarkInfo.size.width * previewRatio,
      height: watermarkInfo.size.height * previewRatio,
    })
    setPosition({
      x: watermarkInfo.position.x * previewRatio,
      y: watermarkInfo.position.y * previewRatio,
    })
    setOpacity(watermarkInfo.opacity)
  }

  const onExecCompositionHandler = (): void => {
    props.onExecComposition({
      src: watermark,
      size: {
        width: size.width / previewRatio,
        height: size.height / previewRatio,
      },
      position: {
        x: position.x / previewRatio,
        y: position.y / previewRatio,
      },
      opacity: opacity,
      previewSize: previewOriginSize,
    })
  }

  const onWatermarkSelectHandler = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const files = event.currentTarget.files
    if (!files || files.length === 0) {
      return
    }

    const image = await asyncImageReader(window.URL.createObjectURL(files[0]))
    image.width *= previewRatio
    image.height *= previewRatio

    let width = image.width
    let height = image.height

    let tmpRatio = 1
    if (previewSize.width < width) {
      tmpRatio *= previewSize.width / width
      width *= tmpRatio
      height *= tmpRatio
    }
    if (previewSize.height < height) {
      tmpRatio *= previewSize.height / height
      width *= tmpRatio
      height *= tmpRatio
    }

    setSize({
      width: width,
      height: height,
    })
    setPosition({
      x: 0,
      y: 0,
    })
    setWatermark(await asyncFileReader(files[0]))
  }

  const onDragStop: RndDragCallback = React.useCallback((e, d): void => {
    setPosition({
      x: d.x,
      y: d.y,
    })
  }, [])

  const onResizeStop: RndResizeCallback = (
    e,
    direction,
    ref,
    delta,
    newPosition
  ) => {
    if (position.x !== newPosition.x || position.y !== newPosition.y) {
      setPosition({
        x: newPosition.x,
        y: newPosition.y,
      })
    }

    setSize({
      width: ref.offsetWidth,
      height: ref.offsetHeight,
    })
  }

  const onClear = (): void => {
    setWatermark('')
  }

  const isUpdated = (): boolean => {
    if (watermark === '') {
      return watermarkInfo.src === watermark
    }

    return (
      watermarkInfo.src === watermark &&
      watermarkInfo.size.width * previewRatio === size.width &&
      watermarkInfo.size.height * previewRatio === size.height &&
      watermarkInfo.position.x * previewRatio === position.x &&
      watermarkInfo.position.y * previewRatio === position.y &&
      watermarkInfo.opacity === opacity
    )
  }

  return (
    <Modal
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Paper className={classes.root}>
        <div
          style={{ width: previewSize.width, height: previewSize.height }}
          className={classes.imageArea}
        >
          <img src={previewSrc} alt="" className={classes.previewImage} />
          <div className={classes.previewImageCover} />
          <Watermark
            size={size}
            position={position}
            opacity={opacity / 100}
            src={watermark}
            onDragStop={onDragStop}
            onResizeStop={onResizeStop}
          />
        </div>
        <Grid container justify="space-between" style={{ marginBottom: 10 }}>
          <Grid item sm={3}>
            <Button
              variant="contained"
              onClick={onClear}
              disabled={watermark === ''}
              fullWidth
            >
              クリア
            </Button>
          </Grid>
          <Grid item sm={4}>
            <Button variant="contained" color="primary" disabled fullWidth>
              前回の画像を使用
            </Button>
          </Grid>
          <Grid item sm={3}>
            <Button color="default" variant="contained" fullWidth>
              ファイル選択
              <input
                type="file"
                className={classes.inputFileHide}
                accept="image/jpeg,image/png,image/gif"
                multiple
                onChange={onWatermarkSelectHandler}
              />
            </Button>
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={2}>
          <Grid item sm={2} style={{ textAlign: 'right' }}>
            <label>透明度</label>
          </Grid>
          <Grid item sm={3}>
            <OutlinedInput
              type="number"
              value={opacity}
              onChange={onOpacityTextChange}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              inputProps={{ className: classes.opacityText }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item sm={2} style={{ textAlign: 'right' }}>
            <label>薄い</label>
          </Grid>
          <Grid item sm>
            <Slider
              value={opacity}
              aria-labelledby="opacity-slider"
              onChange={onOpacitySliderChange}
            />
          </Grid>
          <Grid item sm={2}>
            <label>濃い</label>
          </Grid>
        </Grid>

        <Divider />

        <Grid container justify="space-between" className={classes.bottomGrid}>
          <Grid item sm={3}>
            <Button
              color="default"
              variant="contained"
              fullWidth
              onClick={onCancelHandler}
            >
              キャンセル
            </Button>
          </Grid>
          <Grid item sm={4}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={onExecCompositionHandler}
              disabled={isUpdated()}
            >
              全001画像に適用
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  )
}

export default React.memo(WatermarkSetting)
